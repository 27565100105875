<div class="resources-wrap" *ngIf="!noVideo && carouselReady">
    <div class="container container-mobile">
        <h2>Resources</h2>
        <p class="headerText">Watch to learn more about the importance of making a Will, considering your healthcare options, and thinking about your funeral wishes.</p>

        <owl-carousel-o [options]="customOptions" id="resourcesSlider">  
            <ng-container *ngFor="let data of videoData" >
              <ng-template  carouselSlide [id]="data._id">
                <div class="sliderContainer">
                <div class="video-wrap">
                    <img [src]="data.thumbnail" alt="Resources">
                    <div class="hoverDark"></div>
                    <div class="play-wrap" data-bs-toggle="modal" data-bs-target="#videoModal" data-bs-attr="data.s3FileKey" (click)="openVideo(data)">
                        <img src="../../../assets/images/play.svg" alt="Play" class="hoverImg">
                        <img src="../../../assets/images/play-white.svg" alt="Play" class="hoverWhiteImg">
                    </div>
                </div>
                <h2>{{data.title}}</h2>
                <p class="titleDesc" [title]="data.description">{{ (data.description.length > 87) ? (data.description | slice:0:87) + '...' : data.description }}
                  <span class="article-description">{{data.description}}</span></p>
              </div>
              </ng-template>
            </ng-container>              
        </owl-carousel-o>

    </div>
</div>


  <div class="resources-wrap2 article-wrap" *ngIf="!noTestimonial && carouselReady">
    <div class="container container-mobile">
        <h2 class="mt-5">Testimonials</h2>
        <p class="headerText">Hear what our users have to say about Catholic Legacy and <a style="cursor: pointer;" (click)="contactUs()">contact us</a> with your feedback.</p>
        <owl-carousel-o [options]="customOptions2" id="resourcesSlider2">  
            <ng-container *ngFor="let data of testimonailData" >
              <ng-template carouselSlide [id]="data._id">
                <div class="sliderContainer" [ngClass]="{'oneTestimony': testimonailData.length === 1}">
                <div class="video-wrap-testimonial">
                    <img [src]="data.thumbnail" alt="Resources">
                    <!-- <div class="arrow"></div> -->
                </div>
                <!-- <h2>{{data.title}}</h2> -->
                <div class="description-cotainer">
                    
                    <blockquote style="height: fit-content;">
                        <!-- <img src="/assets/images/icons8-quote-left-24.png" style="width:9%; height:8%;"> -->
                        <!-- {{data.description}} -->
                        {{ (data.clientTestimony.length > 200) ? (data.clientTestimony | slice:0:200) + '...' : data.clientTestimony }}
                        <!-- <img src="/assets/images/icons8-quote-right-24.png" style="width:9%; height:8%;"> -->
                
                    </blockquote>
                    <div class="client-info">
                        <div class="client-name">
                          {{ (data.clientName.length > 20) ? (data.clientName | slice:0:20) + '...' : data.clientName }}
                        </div>
                        <!-- <div class="client-designation">Client Designation</div> -->
                    </div>
                    
                </div>
                
                </div>
              </ng-template>
            </ng-container>              
        </owl-carousel-o>
    </div>   
</div>

<div class="resources-wrap article-wrap" *ngIf="!noArticle && carouselReady">
    <div class="container container-mobile">
        <h2 class="mt-5">Articles</h2>
        <p class="headerText">Access a wealth of resources from Relevant Radio writers and show hosts about critical issues and teachings of the Catholic Church on end-of-life decisions.</p>
        <owl-carousel-o [options]="customOptions" id="resourcesSlider">  
            <ng-container *ngFor="let data of articleData">
              <ng-template carouselSlide [id]="data._id">
                <div class="video-wrap">
                    <a [href]="data.url" target="_blank"><img [src]="data.thumbnail" alt="Resources"></a>
                </div>
                <h2><a [href]="data.url" target="_blank" class="link-unstyled">{{data.title}}</a></h2>
                <!-- <p class="titleDesc" [title]="data.description">{{ (data.description.length > 90) ? (data.description | slice:0:90) + '...' : data.description }}</p> -->
                <p class="titleDesc"  [title]="data.description" ><a [href]="data.url" target="_blank" class="link-unstyled">
                  {{ (data.description.length > 90) ? (data.description | slice:0:90) + '...' : data.description }}
                  <span  class="article-description">{{data.description}}</span></a></p>
              </ng-template>
            </ng-container>              
        </owl-carousel-o>
    </div>
</div>


<div class="modal fade" id="videoModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="videoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeVideoModal()" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <video *ngIf="!isVideoYoutubeUrl" width="100%" controls autoplay [src]="videoUrl" type="video/mp4">
                <!-- <source [src]="videoUrl" type="video/mp4"> -->
                Your browser does not support the video tag.
            </video>
            <iframe width="100%" height="380" [src]="youTubeUrl" *ngIf="isVideoYoutubeUrl">
            </iframe>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
</div>