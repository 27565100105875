<div class="nav-col">
    <nav class="nav flex-column mb-4" id="navigation">
        <a class="nav-link profile" href="/my-profile">My Profile</a>
        <!-- <a class="nav-link address" href="/my-address">My Address</a> -->
        <a class="nav-link forms" href="/my-form-status">My Forms in Progress</a>
        <a class="nav-link file" href="/my-files">My Completed Documents</a>
        <a class="nav-link documents" href="/shared-doc">Documents Shared with Me</a>
        <a class="nav-link change-password" href="/change-password">Change Password</a>
        <a class="nav-link" data-bs-toggle="modal" data-bs-target="#surveyModal">Survey</a>
        <a class="nav-link" data-bs-toggle="modal" data-bs-target="#logoutModal">Logout</a>                            
    </nav>
</div>

<div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
            <button type="button" #cancelLogoutModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="logoutModalLabel">Are you sure?</h5>
            You want to logout
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="visibility: hidden;" id="cancelLogoutModal">Cancel</button> -->
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="OnLogout()">Ok</button>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="surveyModal" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="static" aria-labelledby="surveyModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <app-survey-form></app-survey-form>
    </div>
  </div>
</div>
