import { Component, OnInit, ViewChild, Inject, ElementRef, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CustomValidator } from './../../_common/custom-validators';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api-data.service';
import { URLS } from '../../_config/api.config';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-resources-slider',
    templateUrl: './resources-slider.component.html',
    styleUrls: ['./resources-slider.component.scss'],
})
export class ResourcesSliderComponent implements OnInit {

    private subscription: Subscription = new Subscription();
    public title: any;
    public isLoading = false;
    videoData: any;
    articleData: any;
    testimonailData: any;
    videoUrl: any;
    youTubeUrl: any;
    isVideoYoutubeUrl: boolean = false;
    noVideo: boolean = false;
    noArticle: boolean = false;
    noTestimonial: boolean = false;

    carouselReady: boolean = false;

    customOptions: OwlOptions = {
        loop: false,
        mouseDrag: false,
        touchDrag: true,
        pullDrag: false,
        dots: true,
        navSpeed: 700,
        navText: ['<img src="../../../assets/images/left-orange-arrow.webp">', '<img src="../../../assets/images/right-orange-arrow.webp">'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1024: {
                items: 3
            },
            1200: {
                items: 3
            },
            1350: {
                items: 4
            }
        },
        nav: true
    }

    customOptions2: OwlOptions = {
        loop: false,
        mouseDrag: false,
        touchDrag: true,
        pullDrag: false,
        dots: true,
        navSpeed: 700,
        navText: ['<img src="../../../assets/images/left-orange-arrow.webp">', '<img src="../../../assets/images/right-orange-arrow.webp">'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1024: {
                items: 2
            },
            1200: {
                items: 2
            },
            1350: {
                items: 2
            }
        },
        nav: true
    }

    constructor(
        private router: Router,
        private datePipe: DatePipe,
        private commonService: CommonService,
        private dataService: ApiService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        @Inject(DOCUMENT) private document: Document,
        private sanitize: DomSanitizer,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngOnInit() {
        const offset = this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
        if (offset != 0) {
            this.document.getElementsByTagName("body")[0].style.overflowY = "auto";
        }

        this.getVideo();
        this.getArticle();
        this.getTestimonail();

        setTimeout(() => {
            this.carouselReady = true;
        }, 2000); // Adjust the delay time as needed
    }

    getVideo() {
        const params = {
            isActive: true
        }
        this.subscription.add(
            this.dataService.get(URLS.getVideo, params).subscribe(data => {
                if (data['code'] == 200) {
                    this.videoData = data['data']['result'];
                    if (data['data']['result'].length <= 0) {
                        this.noVideo = true;
                    } else {
                        this.noVideo = false;
                    }
                } else {
                    this.toastr.error(data['message'], "Error");
                }
            }, error => {
                this.toastr.error(error['message'], "Error");
            })
        )
    }

    getArticle() {
        this.subscription.add(
            this.dataService.get(URLS.getArticle).subscribe(data => {
                if (data['code'] == 200) {
                    this.articleData = data['data']['result'];
                    if (data['data']['result'].length <= 0) {
                        this.noArticle = true;
                    } else {
                        this.noArticle = false;
                    }
                } else {
                    this.toastr.error(data['message'], "Error");
                }
            }, error => {
                this.toastr.error(error['message'], "Error");
            })
        )
    }
    getTestimonail() {
        const params = {
            isActive: true
        }
        this.subscription.add(
            this.dataService.get(URLS.getTestimonail, params).subscribe(data => {
                if (data['code'] == 200) {
                    this.testimonailData = data['data']['result'];
                    if (data['data']['result'].length <= 0) {
                        this.noTestimonial = true;
                    } else {
                        this.noTestimonial = false;
                    }
                } else {
                    this.toastr.error(data['message'], "Error");
                }
            }, error => {
                this.toastr.error(error['message'], "Error");
            })
        )
    }

    openVideo(data) {

        this.videoUrl = '';
        this.youTubeUrl = '';

        if (data.url) {
            if (data.url && data.url.includes('www.youtube.com')) {
                this.isVideoYoutubeUrl = true;
                if (data.url.includes('https://www.youtube.com/watch?v=')) {
                    data.url = data.url.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/")
                }
                this.youTubeUrl = this.sanitize.bypassSecurityTrustResourceUrl(data.url);

            } else {
                this.isVideoYoutubeUrl = false;
                this.videoUrl = this.sanitize.bypassSecurityTrustResourceUrl(data.url);
            }
        } else {
            this.videoUrl = this.sanitize.bypassSecurityTrustResourceUrl(data.s3FileKey);
        }

        this.subscription.add(
            this.dataService.put(URLS.updateVideo + '?_id=' + data._id).subscribe(data => {
                if (data['code'] == 200) {

                } else {
                    this.toastr.error(data['message'], "Error");
                }
            }, error => {
                this.toastr.error(error['message'], "Error");
            })
        )
    }

    closeVideoModal() {
        this.videoUrl = '';
        this.youTubeUrl = '';
        this.isVideoYoutubeUrl = false;
    }


    currentIndex = 0;

    prevSlide() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        }
    }

    nextSlide() {
        if (this.currentIndex < this.articleData.length - 1) {
            this.currentIndex++;
        }
    }

    contactUs() {
        this.router.navigateByUrl('/contact-us');
    }
}
