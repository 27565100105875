import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private location: Location,
        private authervice: AuthenticationService,
        private commonService: CommonService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const isLoggedIn = this.authervice.hasToken();

        let routeUrl: any;
        if (next.url && next.url.length > 0) {
            routeUrl = next.url[0].path;
        }
        if (isLoggedIn) {
            if (routeUrl && (routeUrl.includes('login') || routeUrl.includes('create-new-password') || routeUrl.includes('create-account') || routeUrl.includes('forgot-password'))) {
                if (this.location.getState()) {
                    this.location.back();
                } else {
                    this.router.navigate(['/home']);
                }
                return false;
            }
            // authorised so return true
            return true;
        } else {
            if (routeUrl && (routeUrl.includes('login') || routeUrl.includes('create-new-password') || routeUrl.includes('create-account') || routeUrl.includes('forgot-password'))) {
                return true;
            }
            this.commonService.localStorageClear();
            this.router.navigate(['/login']);
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;

    }
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.canActivate(childRoute, state)
    }


}
