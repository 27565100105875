import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    public isLogin: boolean = false;
    copyRightYear = new Date().getFullYear();

    constructor(
        private router: Router,
    ) { }

    ngOnInit() {
        let obj = ['/login', '/forgot-password', '/create-account', '/create-new-password'];

        let url = this.router.url.split('?')[0];

        if (!obj.includes(url)) {
            this.isLogin = true;
        } else {
            this.isLogin = false;
        }

    }

    goToPage(page) {
        this.router.navigate([page]);
    }

}
