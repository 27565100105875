<section class="footer">
    <hr *ngIf="isLogin">
    <div class="container">
        <div class="row footer-wrap" *ngIf="isLogin">
            <div class="col-lg-5 col-md-12">
                <h2>About Us</h2>
                <!-- <p>Wills, funeral plans, and healthcare directives can be challenging to think about. Firstly, these all deal with the end of life which, aside from the promise of eternal life, few people want to think about.</p> -->
                <p class="first-p">CatholicLegacy.com provides resources and guidance to help you plan for the future
                    with confidence and clarity. We
                    offer support in navigating essential decisions about your estate, healthcare preferences, and
                    funeral wishes, ensuring
                    your values and beliefs are reflected. Our team's expertise in estate law, canon law, and Catholic
                    teachings empowers
                    you to make informed choices that bring peace of mind to you and your loved ones.</p>
            </div>
            <div class="col-lg-2 col-md-12">
                <h2>Quick Links</h2>
                <ul>
                    <li><a href="/resources">Resources & FAQ</a></li>
                    <li><a (click)="goToPage('/contact-us')">Contact Us</a></li>
                    <li><a href="/privacy-policy" target="_blank">Privacy Policy</a></li>
                    <li><a href="/terms-of-use" target="_blank">Terms of Use</a></li>
                    <li><a href="/press">Press</a></li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-12">
                <h2>Office Address</h2>
                <p>Relevant Radio <br>
                    680 Barclay Blvd <br>
                    Lincolnshire, IL 60069</p>
            </div>
            <div class="col-lg-3 col-md-12 company-detail">
                <p>Catholic Legacy is Presented by</p>
                <!-- <a class="url" href="https://relevantradio.com/" target="_blank">relevantradio.com</a> -->
                <a href="https://relevantradio.com/" target="_blank"><img src="./assets/images/relevant-logo.jpg"
                        alt="Relevant-Logo"></a>
                <!-- <h2>https://relevantradio.com/</h2> -->
            </div>
        </div>
        <div class="copy-wrap">
            <p>&copy; {{copyRightYear}} Catholic Legacy. All Rights Reserved.</p>
        </div>
    </div>
</section>