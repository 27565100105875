import { environment } from '../../environments/environment';

export const URLS = Object({
    //Authentication
    'login': `${environment.apiUrl}/login`,
    'signup': `${environment.apiUrl}/register`,
    'forgotPassword': `${environment.apiUrl}/forgot-password`,
    'resetPassword': `${environment.apiUrl}/reset-password`,
    'changePassword': `${environment.apiUrl}/change-password`,
    'emailVerification': `${environment.apiUrl}/send-verification-mail`,
    'verifyEmail': `${environment.apiUrl}/verify-email`,
    'logout': `${environment.apiUrl}/logout`,

    //Home
    'getVideo': `${environment.apiUrl}/home/video/get`,
    'updateVideo': `${environment.apiUrl}/home/video/count`,
    'getValidToken': `${environment.apiUrl}/user/isValidToken`,
    'getHomePageFormData': `${environment.apiUrl}/home/forms/get`,
    'getArticle': `${environment.apiUrl}/home/article/get`,

    //Profile
    'getProfile': `${environment.apiUrl}/profile/get`,
    'updateProfile': `${environment.apiUrl}/profile/update`,

    //Address
    'saveAddress': `${environment.apiUrl}/address/add`,
    'getAddress': `${environment.apiUrl}/address/get`,
    'updateAddress': `${environment.apiUrl}/address/update`,
    'deleteAddress': `${environment.apiUrl}/address/delete`,

    //CMS
    'getPrivacy': `${environment.apiUrl}/home/cms/getById`,
    'getTerms': `${environment.apiUrl}/home/cms/getById`,
    'getFaq': `${environment.apiUrl}/home/faq/get`,

    //Contact us
    'saveContactUs': `${environment.apiUrl}/home/contact/add`,

    //My files
    // 'getWillFiles': `${environment.apiUrl}/files/get`,
    // 'getFuneralFiles': `${environment.apiUrl}/files/get`,
    // 'getHealthFiles': `${environment.apiUrl}/files/get`,
    'getFormFiles': `${environment.apiUrl}/files/get`,
    'deleteFile': `${environment.apiUrl}/files/delete`,
    'downloadFile': `${environment.apiUrl}/files/download`,
    'sharedFile': `${environment.apiUrl}/files/share-pdf`,
    'getSharedWith': `${environment.apiUrl}/files/shared-with`,
    'fileUpload': `${environment.apiUrl}/files/convert/upload`,
    'sharedMe': `${environment.apiUrl}/files/shared-me`,
    'sharedDelete': `${environment.apiUrl}/files/shared-me/delete`,
    'signedFileUpload': `${environment.apiUrl}/files/upload`,
    'emailDocMe': `${environment.apiUrl}/files/email/me`,

    // Form builder
    'getFormList': `${environment.apiUrl}/form/list`,
    'getFilledFormDetails': `${environment.apiUrl}/form/getfilledformdetail`,
    'formSaveAnswer': `${environment.apiUrl}/form/saveanswer`,
    'formSaveProgress': `${environment.apiUrl}/form/saveProgressIndex`,
    'formUpdateStatus': `${environment.apiUrl}/form/update-status`,
    'getPdfData': `${environment.apiUrl}/pdf/getdata`,
    'saveDonation': `${environment.apiUrl}/form/donate/save`,

    'genratePdf': `${environment.apiUrl}/files/generate-pdf`,

    'deleteFormData': `${environment.apiUrl}/form/data/delete`,
    //Funeral Audio
    'getSongTypeList': `${environment.apiUrl}/form/audio/category`,
    'getSongList': `${environment.apiUrl}/form/audio/list`,

    // Notification
    'getNotificationList': `${environment.apiUrl}/user/notification/get`,
    'getNotificationCount': `${environment.apiUrl}/user/notification/count`,
    'notificationStatus': `${environment.apiUrl}/user/notification/update-status`,

    //
    'getDonationCount': `${environment.apiUrl}/form/donate/counts`,
    //Testimonail 
    'getTestimonail': `${environment.apiUrl}/home/testimonial/get`,

    //Stripe Payment
    'amountSubmit': `${environment.apiUrl}/user/payment/create-checkout-session`,
    'paymentConfirmation': `${environment.apiUrl}/user/payment/session-status`,
    'cancelSession': `${environment.apiUrl}/user/payment/session/expire`,

    //sitemap
    'siteMapList': `${environment.apiUrl}/sitemap/list`,
    'siteMapDetail': `${environment.apiUrl}/sitemap/details`,

    //survey Form
    'surveyForm': `${environment.apiUrl}/survey`,
});

export const SECRET_KEY: string = '$2a$10$e.oPc.dyrwRoQCpDvO9Rhe';
