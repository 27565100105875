import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { RESPONSE, REQUEST } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {

    constructor() { }

    ngOnInit(): void { }
}
