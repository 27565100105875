import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

    public title: any;
    
    constructor(
        private router: Router,
    ) {}

    @Input() additionalMsg: string;

    ngOnInit() {
        let url = this.router.url.replace('/', '',);
        let search = '-';
        let replaceWith = ' ';
        this.title = url.split(search).join(replaceWith);
        // find my forms page
        let splitTitle = this.title.split('/');
        let firstPath = splitTitle[0];
        if (firstPath === 'my forms') {
            this.title = 'My Forms'; 
        }
        if(this.title === 'my files'){
            this.title = 'My Completed Documents';
        } else if(this.title === 'shared doc'){
            this.title = 'Documents Shared with Me';
        }
        
    }

    onHome(){        
        this.router.navigateByUrl('/home');
    }
    
}
