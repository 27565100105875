<div class="parent-container">
    <div class="container">
        <div class="largeScreen">
        <div class="header">
            <h2>Learn How Catholic Legacy Meets Your Needs</h2>
        </div>
        <div class="content-wrapper">
            <div class="text-left">
                <div *ngFor="let item of textItems; let i = index" [ngClass]="{'active': item.id === activeItemId}"
                    (mouseover)="onHover(item.id)" (click)="redirectionUrl(i,'textItems')" class="text-item">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.description }}</p>
                </div>
            </div>

            <div class="image-section">
                <img *ngFor="let image of images" [src]="image.src" [alt]="image.alt"
                    [class.show]="image.id === activeItemId" class="center-image">
            </div>

            <div class="text-right">
                <div *ngFor="let item of rightTextItems; let i = index" [ngClass]="{'active': item.id === activeItemId}"
                    (mouseover)="onHover(item.id)" (click)="redirectionUrl(i,'rightTextItems')" class="text-item">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.description }}</p>
                </div>
            </div>
        </div>
        </div>
        <div class="carousel-container"> 
           <h2 class="carousel-header">Learn How Catholic Legacy Meets Your Needs</h2>
            <owl-carousel-o [options]="carouselOptions" id="landing-carousel">
                <ng-container *ngFor="let item of carouselItems">
                  <ng-template carouselSlide>
                    <div class="item">
                      <div class="image-container">
                        <img [src]="item.image.src" [alt]="item.image.alt" />
                      </div>
                      <h3 (click)="redirect(item)">{{ item.text.title }}</h3>
                      <p>{{ item.text.description }}</p>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
          </div>
    </div>
</div>
