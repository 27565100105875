import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
    textItems = [
        { id: 1, title: 'For Parents', description: 'Learn why having a Will is critical for your children and their safety.', url: 'parents' },
        { id: 2, title: 'When do you need a Will?', description: 'Now is the time to secure your future. Our platform makes it easy to create a will, offering step-by-step guidance to ensure your wishes are clearly outlined.', url: 'will' },
        { id: 3, title: 'For Funeral Pre-Planning', description: 'Document and share your intentions with loved ones. Use our tool to pre-plan your funeral.', url: 'planning' },
        { id: 4, title: 'For Faithful Catholics', description: 'Learn how Catholic Legacy helps you support causes that matter.', url: 'faith' },
        { id: 5, title: 'Upcoming Surgeries/Medical Situations?', description: 'Before this significant life event, please document your intentions and make sure your values are honored.', url: 'health' },
    ];

    // Right side text items
    rightTextItems = [
        { id: 6, title: 'For Pet Owners', description: 'Learn how a Will is needed to protect your pets’ future.', url: 'pets' },
        { id: 7, title: 'Online Will vs Estate Attorney', description: 'Learn how lawyers have helped build Catholic legacy and why online forms work.', url: 'estate' },
        { id: 8, title: 'What to do when a loved one passes?', description: 'Plan a special service for your loved one and document all logistics.', url: 'funeral' },
        { id: 9, title: 'How a Healthcare Directive benefits you', description: 'Don’t let hospitals control what happens to you; create a healthcare directive today.', url: 'future' },
        // { id: 10, title: 'For Parish', description: ' Simplify Funeral Plannning For you parish', url: 'parish' }
    ];

    images = [
        { id: 1, src: '../../../assets/images/parents.jpg', alt: 'Image 1' },
        { id: 2, src: '../../../assets/images/do.jpg', alt: 'Image 2' },
        { id: 3, src: '../../../assets/images/funeral.jpg', alt: 'Image 3' },
        { id: 4, src: '../../../assets/images/faithful.jpg', alt: 'Image 4' },
        { id: 5, src: '../../../assets/images/upcoming.jpg', alt: 'Image 5' },
        { id: 6, src: '../../../assets/images/pet.jpg', alt: 'Image 6' },
        { id: 7, src: '../../../assets/images/online.jpg', alt: 'Image 7' },
        { id: 8, src: '../../../assets/images/what.jpg', alt: 'Image 8' },
        { id: 9, src: '../../../assets/images/how.png', alt: 'Image 9' },
        // { id: 10, src: '../../../assets/images/parents.jpg', alt: 'Image 10' }
    ];

    carouselOptions = {
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };

    activeItemId: number = 1; // Default active item
    carouselItems = [];

    constructor(private router: Router) { }

    ngOnInit(): void {
        this.activeItemId = 1;
        const allTextItems = [...this.textItems, ...this.rightTextItems];

        this.carouselItems = allTextItems.map(textItem => {
            const image = this.images.find(img => img.id === textItem.id);
            return {
                text: textItem,
                image: image ? image : { src: '', alt: '' } // Fallback if image not found
            };
        });
    }

    onHover(itemId: number): void {
        this.activeItemId = itemId;
    }

    redirectionUrl(index, array) {
        if (array == 'rightTextItems') {
            this.rightTextItems[index].url
            // this.router.navigate([this.rightTextItems[index].url]);
            this.router.navigate([this.rightTextItems[index].url]).then(() => {
                window.scrollTo(0, 0); // Manually scroll to the top
            });
        } else if (array == 'textItems') {
            // this.router.navigate([this.textItems[index].url]);
            this.router.navigate([this.textItems[index].url]).then(() => {
                window.scrollTo(0, 0); // Manually scroll to the top
            });

        }
    }
    redirect(item) {
        this.router.navigate([item.text.url]);
    }
}

