import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-creating-will',
    templateUrl: './creating-will.component.html',
    styleUrls: ['./creating-will.component.scss']
})
export class CreatingWillComponent implements OnInit {

    constructor(private router: Router,
        private commonService: CommonService
    ) { }

    ngOnInit(): void {
    }

    gotoWillForm() {

        const userId = this.commonService.localStorageGet('userId')
        if (!userId) {
            sessionStorage.setItem('formId', '6490049fe7bc6b6649320fe5');
        }
        this.commonService.localStorageSet('my-form-status-id', '6490049fe7bc6b6649320fe5');
        this.router.navigate(['my-forms']);
    }
}
