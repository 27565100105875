<div class="bg-image">
    <div class="container section-wrap">
        <div class="verifyEmail" *ngIf="isVerified">
            <p>Email is verified successfully</p>
            <div class="d-flex justify-content-between align-items-center">
                <label><span>{{transform(counter)}}</span></label>
            </div>
        </div>
    </div>
</div>
        