import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonService } from './services/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from './services/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'relevant-redio-web';
    isServer: boolean = false;

    constructor(
        @Inject(DOCUMENT) private document: any,
        @Inject(PLATFORM_ID) private platformId: Object,
        private commonService: CommonService,
        private router: Router,
        private meta: Meta,
        private titleService: Title,
        private authService: AuthenticationService,
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                this.setMetaTags();

                const canonicalUrl = event.urlAfterRedirects;
                this.updateCanonicalTag(canonicalUrl);

                let isLogin = this.authService.hasToken();
                let userEmail = this.commonService.localStorageGet('email');

                if (isLogin) {
                    if (window.clarity) {
                        window.clarity("identify", userEmail, canonicalUrl);
                    }
                }
            });
    }

    ngOnInit(): void {
        this.updateCanonicalTag('/');
        this.isServer = isPlatformServer(this.platformId);
    }

    setMetaTags() {
        // const currentUrl = this.router.url;

        // if (currentUrl === '/will') {
        const title = this.getTitle(
            this.router.routerState,
            this.router.routerState.root
        );


        if (title && title.length) {
            this.titleService.setTitle(title[title.length - 1].title);

            this.meta.updateTag({
                property: "og:title",
                content: title[title.length - 1].title,
            });
            this.meta.updateTag({ name: 'description', content: title[title.length - 1].desc });
            this.meta.updateTag({
                property: "og:description",
                content: title[title.length - 1].desc,
            });
        }
        // }
    }

    updateCanonicalTag(url: string) {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events.subscribe((event: any) => {
                if (event instanceof NavigationEnd) {
                    let currentUrl = window.location.href;
                    // if (event.url == '/en' || event.url == '/ar') {
                    currentUrl = `${currentUrl}/home`
                    // }
                }
            })
            const existingCanonical: HTMLLinkElement = this.getOrCreateCanonicalTag();
            existingCanonical.setAttribute('href', `${window.location.origin}${url}`);
        }
    }

    private getOrCreateCanonicalTag(): HTMLLinkElement {
        let link: HTMLLinkElement = this.document.querySelector("link[rel='canonical']");
        if (!link) {
            link = this.document.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.document.head.appendChild(link);
        }
        return link;
    }

    public getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push({ title: parent.snapshot.data.title, desc: parent.snapshot.data.description });
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }

}
