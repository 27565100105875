import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidator } from './../../_common/custom-validators';
import { timer, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { ApiService } from '../../services/api-data.service';
import { URLS } from '../../_config/api.config';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
    
    private subscription: Subscription = new Subscription(); 
    public countDown: any = Subscription;
    token: any;   
    counter: any;
    tick = 1000;
    public isLoading: boolean = false;
    public isVerified: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private commonService: CommonService, 
        private dataService: ApiService,
        private toastr: ToastrService,
        private activeRoute: ActivatedRoute
    ) { 
      this.activeRoute.queryParams.subscribe(params => {
        this.token = params['token'];
      });
    }

    ngOnInit() {
      this.verifyEmail();
    }

    verifyEmail(){
      const queryParams = {
        token: this.token
      }
      this.isLoading = true;
      this.subscription.add(
        this.dataService.get(URLS.verifyEmail, queryParams).subscribe(data => {
          if (data['code'] == 200) {            
            this.isLoading = false;
            this.isVerified = true;
            this.otpCounter();
          } else {
            this.isLoading = false;
            this.toastr.error(data['message'], "Error");
          }
        }, error => {
          this.isLoading = false;
          this.toastr.error(error['message'], "Error");
        })
      )
    }

    otpCounter() {
      this.counter = 5;
      this.countDown = timer(0, this.tick)
          .pipe(take(this.counter))
          .subscribe(() => {
              --this.counter;
              if (this.counter == 0) {
                  this.countDown.unsubscribe();
                  this.router.navigate(['/login']);
              }
          });
    }
  
    // transfor counter in time format
    transform(value: number): string {
      const minutes: number = Math.floor(value / 60);
      return (
          ('00' + minutes).slice(-2) +
          ':' +
          ('00' + Math.floor(value - minutes * 60)).slice(-2)
      );
    }
    
}
