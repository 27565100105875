import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneNumberMask]'
})
export class PhoneNumberMaskDirective {
  @HostListener('input', ['$event.target'])
  onInput(input: HTMLInputElement) {
    // Remove all non-numeric characters
    const numericValue = input.value.replace(/\D/g, '');

    // Limit the input to 10 digits
    const maxLength = 10;
    const formattedValue = numericValue.substr(0, maxLength);

    // Format the phone number with dashes every 3 digits
    const formattedPhoneNumber = this.formatPhoneNumber(formattedValue);

    // Update the input field with the formatted value
    input.value = formattedPhoneNumber;
  }

  private formatPhoneNumber(inputValue: string): string {
    if (inputValue.length > 3) {
      // Add a dash after the first 3 digits and then after the next 3 digits
      const firstPart = inputValue.substring(0, 3);
      const secondPart = inputValue.substring(3, 6);
      const remainingDigits = inputValue.substring(6);
      
      return `${firstPart}-${secondPart}${remainingDigits ? '-' : ''}${remainingDigits}`;
    }
    return inputValue;
  }

}
