import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LowercaseUrlGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    // Check if the URL contains any uppercase letters
    if (url !== url.toLowerCase()) {
      // Redirect to the lowercase version of the URL
      this.router.navigateByUrl(url.toLowerCase());
      return false; // Prevent the original route from being activated
    }
    return true; // Allow the route to be activated
  }
}