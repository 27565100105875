<div class="will-legacy-section">
    <div class="container container-mobile">
        <h2>Creating a Will is as easy as 1, 2, 3!</h2>
        
    </div>
    <div class="image-row">
        <img src="../../../../assets/images/signUp1.png" alt="Step 1" class="step-image">
        <img src="../../../../assets/images/downArrow4.svg" alt="Down Arrow" class="arrow-image smallScreenArrow">
        <img src="../../../../assets/images/upArrow2.svg" alt="Up Arrow" class="arrow-image largeScreenArrow">
        <img src="../../../../assets/images/answerSome3.png" alt="Step 2" class="step-image">
        <img src="../../../../assets/images/downArrow4.svg" alt="Down Arrow" class="arrow-image">
        <img src="../../../../assets/images/download5.png" alt="Step 3" class="step-image">
    </div>
    <div class="btn-container">
        <button class="btn btn-primary btn-block action-btn" (click)="gotoWillForm()">START YOUR FREE CATHOLIC
            WILL
            TODAY</button>
    </div>
</div>
