import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { SideNavComponent } from '../sideNav/sideNav.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Output() verifiedMailStatus = new EventEmitter<string>();
    @ViewChild('child') private child: SideNavComponent;
    @ViewChild('cancelHeaderLogoutModal') cancelHeaderLogoutModal;
    @ViewChild('menuModalClose') menuModalClose;
    @ViewChild('openSurveyModal') openSurveyModal;
    @ViewChild('openMenuModal') openMenuModal;
    private subscription: Subscription = new Subscription();
    public isLogin: boolean = false;
    public userName: any;
    notificationCount: any = 0;
    notificatonMessages: any[] = [];
    homeActive = false;
    isLoggingOut = false;

    constructor(
        private router: Router,
        private commonService: CommonService,
        private dataService: ApiService,
        private toastr: ToastrService,
        private authService: AuthenticationService,
        @Inject(DOCUMENT) private document: any,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.commonService.eventObservable.subscribe(data => {
            if (data.event == 'profileDataChange') {
                this.userName = this.commonService.localStorageGet('firstName') + ' ' + this.commonService.localStorageGet('lastName');
            }
        })
    }

    ngOnInit() {
        let isLogin = this.authService.hasToken();

        if (isLogin) {
            this.isLogin = true;
        } else {
            this.isLogin = false;
        }
        const token = this.commonService.localStorageGet('accessToken');
        if (token) {
            this.getNotiCount();
            this.getNotificationList();
        }

        let firstName = this.commonService.localStorageGet('firstName');
        let lastName = this.commonService.localStorageGet('lastName');
        this.userName = firstName + ' ' + lastName;
        this.commonService.profileName.subscribe((res) => {
            this.userName = res;
        });
        this.commonService.updatedNotificationCount.subscribe((res) => {
            this.notificationCount = res;
        });

        if (this.router.url === '/resources') {

            var elems = this.document.getElementsByClassName('resources')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = "active";
            }

        }
        if (this.router.url === '/about-us') {

            var elems = this.document.getElementsByClassName('aboutUs')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = "active";
            }

        }
        if (this.router.url === '/home') {
            this.homeActive = true;
        }
        else if (this.router.url === '/') {
            this.homeActive = true;
        }
        else {
            this.homeActive = false;
        }

    }

    menuCall() {
        this.onSurveyModalClose();
        this.openMenuModal.nativeElement.click();

        if (this.router.url === '/resources') {

            var elems = this.document.getElementsByClassName('resources-nav-link')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = "resources-nav-link active";
            }

        }
        if (this.router.url === '/about-us') {

            var elems = this.document.getElementsByClassName('about-us-nav-link')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = "about-us-nav-link active";
            }

        }
    }

    // go to relevant page
    goToPage(item?) {
        this.router.navigate(['/notification']);
        console.log(item);
    }

    // get notification count
    getNotiCount() {
        this.subscription.add(this.dataService.get(URLS.getNotificationCount).subscribe(
            res => {
                if (res['code'] == 200) {
                    this.notificationCount = res['data']['count'];
                }
            }
        ));
    }

    // get notification list 
    getNotificationList() {
        this.subscription.add(this.dataService.get(URLS.getNotificationList).subscribe(
            res => {
                if (res['code'] == 200) {
                    this.notificatonMessages = res['data']['result'];
                    //   console.log(this.notificatonMessages);
                }
            },
            error => {
                console.log(error);
            }
        ))
    }

    navCall() {
        if (this.router.url === '/my-profile') {

            var elems = this.document.getElementsByClassName('profile-nav-link')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = "profile-nav-link active";
            }

        }
        if (this.router.url === '/my-address') {

            var elems = this.document.getElementsByClassName('address-nav-link')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = "address-nav-link active";
            }

        }
        if (this.router.url === '/my-files') {

            var elems = this.document.getElementsByClassName(' file-nav-link')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = " file-nav-link active";
            }

        }
        if (this.router.url === '/shared-doc') {

            var elems = this.document.getElementsByClassName('shared-nav-link')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = "shared-nav-link active";
            }

        }
        if (this.router.url === '/change-password') {
            var elems = this.document.getElementsByClassName('change-password-nav-link')[0];
            if (elems != null) {
                elems.classList.remove("active");
                elems.className = "change-password-nav-link active";
            }
        }
    }

    onHome() {
        this.homeActive = true;
        this.router.navigateByUrl('/home');
    }

    goToResources() {
        this.homeActive = false;
        this.router.navigateByUrl('/resources');
    }

    goToAboutUs() {
        this.homeActive = false;
        this.router.navigateByUrl('/about-us');
    }

    onLogin() {
        this.homeActive = false;
        this.router.navigateByUrl('/login');
    }

    OnSignUp() {
        this.homeActive = false;
        this.router.navigateByUrl('/create-account');
        this.verifiedMailStatus.emit('go to register page');
    }

    OnLogout() {
        if (!this.isLoggingOut) {
            this.isLoggingOut = true;
            this.subscription.add(
                this.dataService.get(URLS.logout, '', '', true).subscribe(data => {
                    if (data['code'] == 200) {
                        this.isLogin = false;
                        this.commonService.localStorageClear();
                        if (data['code'] == 200) {
                            this.toastr.success('Success', data['message']);
                        }
                        this.cancelHeaderLogoutModal.nativeElement.click();
                        this.router.navigate(['/home']);
                        sessionStorage.clear();
                    } else {
                        this.toastr.error(data['message'], "Error");
                    }
                }, error => {
                    this.toastr.error(error['message'], "Error");
                    this.commonService.localStorageClear();
                    this.router.navigate(['/home']);
                })
            )
        }
    }


    closeMenuModal() {
        this.menuModalClose.nativeElement.click();
        this.openSurveyModal.nativeElement.click();
    }

    closeMainMenuModal() {
        this.menuModalClose.nativeElement.click();
    }

    onSurveyModalClose() {
        this.menuModalClose.nativeElement.click();
        const modal = this.document.getElementById('surveyModal');
        const backdrop = this.document.querySelector('.modal-backdrop');
        if (modal && backdrop && !modal.classList.contains('show')) {
            backdrop.remove();  // Properly remove the backdrop
        }
    }

}
