import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

    public title: any;
    
    constructor(
        private router: Router,
    ) {}

    ngOnInit() {}
    
}
